<template>
  <v-list flat>
    <v-list-item-group color="primary">
      <v-list-item
        v-for="(item, i) in items"
        :key="item.title"
        link
        :to="`/${item.link}`"
        class="font-weight-medium"
        active-class="active"
        :class="user.status == 'blocked' && i ? 'd-none' : ''"
      >
        <v-list-item-icon class="my-3 mr-5">
          <v-img :src="getImage(item.icon)" class="icon"></v-img>
        </v-list-item-icon>
        <v-list-item-content class="py-2">
          <v-list-item-title class="f18">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: 'Dashboard', link: 'dashboard', icon: 'dash' },
        { title: 'Campaigns', link: 'campaigns', icon: 'mail' },
        { title: 'Messages', link: 'messages', icon: 'msg' },
        { title: 'Statistics', link: 'statistics', icon: 'statistic' },
        { title: 'Groups', link: 'groups', icon: 'groups' },
        { title: 'Prices', link: 'prices', icon: 'prices' },
        { title: 'Invoices', link: 'invoices', icon: 'invoices' },
        { title: 'Account', link: 'account', icon: 'accounts' },
        { title: 'REST API', link: 'rest', icon: 'rest' },
        { title: 'SMPP API', link: 'smpp', icon: 'smpp' },
        { title: 'Support', link: 'support', icon: 'support' },
      ],
    };
  },
  methods: {
    getImage(imgPathSegment) {
      return require(`@/assets/icon/${imgPathSegment}.svg`);
    },
  },
  computed: {
    user() {
      return this.$store.getters.account;
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  border-left: 8px solid;
  padding: 0 16px 0 8px !important;

  .v-list-item__title {
    color: #ff5a36 !important;
  }
  .icon {
    filter: invert(53%) sepia(66%) saturate(4339%) hue-rotate(339deg) brightness(102%) contrast(101%);
  }
}
</style>
