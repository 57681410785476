import '@/plugins/axios';

const apiRest = '/customer/api/v1/external-api/rest';
const apiSmpp = '/customer/api/v1/external-api/smpp';

export default {
  state: {
    rest: {
      live: {},
      sandbox: {},
    },
    smpp: {
      live: {},
      sandbox: {},
    },
  },
  actions: {
    //                        REST
    getRest(context) {
      return axios.get(apiRest).then((res) => {
        context.commit('GET_REST', res.data);
      });
    },
    setRest: (context, payload) => {
      context.commit('GET_REST', payload);
    },
    updateRest(context, payload) {
      return axios.patch(apiRest, payload).then((res) => {
        context.commit('GET_REST', res.data);
      });
    },
    newKeyRest(context, payload) {
      return axios.post(apiRest + '/generate-secret', payload).then(() => {
        context.dispatch('getRest');
      });
    },
    addIpRest(context, payload) {
      return axios.post(apiRest + '/allowed-ips', payload).then(() => {
        context.dispatch('getRest');
      });
    },
    deletIpRest(context, payload) {
      return axios
        .delete(apiRest + '/allowed-ips', {
          data: JSON.stringify(payload),
        })
        .then(() => {
          context.dispatch('getRest');
        });
    },
    //                        SMPP
    getSmpp(context) {
      return axios.get(apiSmpp).then((res) => {
        context.commit('GET_SMPP', res.data);
      });
    },
    setSmpp: (context, payload) => {
      context.commit('GET_SMPP', payload);
    },
    updateSmpp(context, payload) {
      return axios.patch(apiSmpp, payload).then((res) => {
        context.commit('GET_SMPP', res.data);
      });
    },
    newKeySmpp(context, payload) {
      return axios.post(apiSmpp + '/generate-credentials', payload).then((res) => {
        context.dispatch('getSmpp');
      });
    },
    addIpSmpp(context, payload) {
      return axios.post(apiSmpp + '/allowed-ips', payload).then((res) => {
        context.dispatch('getSmpp');
      });
    },
    deletIpSmpp(context, payload) {
      return axios
        .delete(apiSmpp + '/allowed-ips', {
          data: JSON.stringify(payload),
        })
        .then(() => {
          context.dispatch('getSmpp');
        });
    },
  },
  mutations: {
    GET_REST: (state, payload) => {
      state.rest = payload;
      return state.rest;
    },
    GET_SMPP: (state, payload) => {
      state.smpp = payload;
      return state.smpp;
    },
  },
  getters: {
    rest(state) {
      return state.rest;
    },
    smpp(state) {
      return state.smpp;
    },
  },
};
