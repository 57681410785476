import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/messages/';

export default {
  state: {
    messagesList: {},
    messages: { access_type: {}, origin: {} },
    messagesStatis: {},
  },
  actions: {
    getMessagesList(context, { pageSize, status, date, page, dateFrom, dateTo }) {
      if (date != 'last_7_days' || status != 'all' || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}?page=${page}&page_size=${pageSize}${status != 'all' ? `&status=${status}` : ''}&date=${date}${
            date == 'custom' ? `&date_from=${dateFrom}` : ''
          }${date == 'custom' ? `&date_to=${dateTo}` : ''}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}?page=${page}&page_size=${pageSize}${status != 'all' ? `&status=${status}` : ''}&date=${date}${
              date == 'custom' ? `&date_from=${dateFrom}` : ''
            }${date == 'custom' ? `&date_to=${dateTo}` : ''}`
          );
        }
        return axios
          .get(
            `${api}?page=${page - 1 || 0}&page_size=${pageSize}${status != 'all' ? `&status=${status}` : ''}&date=${date}${
              date == 'custom' ? `&date_from=${Date.parse(dateFrom)}` : ''
            }${date == 'custom' ? `&date_to=${Date.parse(dateTo)}` : ''}`
          )
          .then((res) => {
            context.commit('GET_MESSAGESLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?date=${date}`).then((res) => {
          context.commit('GET_MESSAGESLIST', res.data);
        });
      }
    },
    setMessagesList: (context, payload) => {
      context.commit('GET_MESSAGESLIST', payload);
    },
    sendMessages(context, payload) {
      return axios.post(api, payload);
    },
    getMessages(context) {
      return axios.get(api + router.app._route.params.msgId).then((res) => {
        context.commit('GET_MESSAGES', res.data);
      });
    },
    setMessages: (context, payload) => {
      context.commit('GET_MESSAGES', payload);
    },
    getMessagesStatis(context, { date, dateFrom, dateTo }) {
      if (date == 'custom') {
        if (`${router.app._route.path}?date=${date}&date_from=${dateFrom}&date_to=${dateTo}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}?date=${date}&date_from=${dateFrom}&date_to=${dateTo}`);
        }
        return axios.get(`${api}statistics?date=custom&date_from=${Date.parse(dateFrom)}&date_to=${Date.parse(dateTo)}`).then((res) => {
          context.commit('GET_MESSAGESSTATIS', res.data);
        });
      } else {
        if (`${router.app._route.path}?date=${date}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}?date=${date}`);
        }
        return axios.get(`${api}statistics?date=${date}`).then((res) => {
          context.commit('GET_MESSAGESSTATIS', res.data);
        });
      }
    },
    setMessagesStatis: (context, payload) => {
      context.commit('GET_MESSAGESSTATIS', payload);
    },
  },
  mutations: {
    GET_MESSAGESLIST: (state, payload) => {
      state.messagesList = payload;
      return state.messagesList;
    },
    GET_MESSAGES: (state, payload) => {
      state.messages = payload;
      return state.messages;
    },
    GET_MESSAGESSTATIS: (state, payload) => {
      state.messagesStatis = payload;
      return state.messagesStatis;
    },
  },
  getters: {
    messagesList(state) {
      return state.messagesList;
    },
    messages(state) {
      return state.messages;
    },
    messagesStatis(state) {
      return state.messagesStatis;
    },
  },
};
