import '@/plugins/axios';

const api = '/customer/api/v1/account';

export default {
  state: {
    account: {},
    accountAddress: {},
    members: [],
    billing: {
      billing_period: {},
      currency: {},
      payment_mode: {},
    },
    accountsList: [{ id: null }],
  },
  actions: {
    getAccount(context) {
      return axios.get(api).then((res) => {
        context.commit('GET_ACCOUNT', res.data);
      });
    },
    setAccount: (context, payload) => {
      context.commit('GET_ACCOUNT', payload);
    },
    updateAccount(context, payload) {
      return axios.put(api, payload).then((res) => {
        context.commit('GET_ACCOUNT', res.data);
        context.dispatch('getAccountsList');
      });
    },
    setPhotoAcc(context, payload) {
      return axios.post(api + '/photo', payload).then((res) => {
        context.commit('GET_ACCOUNT', res.data);
        context.dispatch('getAccountsList');
      });
    },
    deletePhotoAcc(context) {
      return axios.delete(api + '/photo').then(() => {
        context.dispatch('getAccount');
        context.dispatch('getAccountsList');
      });
    },
    confirmEmailAcc(context, payload) {
      return axios.post(api + '/confirm-email', payload).then((res) => {
        context.dispatch('getAccount');
      });
    },
    sendEmailAcc(context) {
      return axios.post(api + '/send-email-confirmation').then((res) => {
        context.commit('GET_ACCOUNT', res.data);
      });
    },
    makeCurrentAcc(context, payload) {
      return axios.post(`${api}s/${payload}/make-current`).then(() => {
        window.location.reload();
      });
    },
    leavelAcc(context, payload) {
      return axios.post(`${api}s/${payload}/leave`);
    },
    getAccountsList(context) {
      return axios.get(api + 's/').then((res) => {
        context.commit('GET_ACCOUNTSLIST', res.data);
      });
    },
    //                               ADDRESS
    getAccountAddress(context) {
      return axios.get(api + '/address').then((res) => {
        context.commit('GET_ACCOUNTADDRESS', res.data);
      });
    },
    setAccountAddress: (context, payload) => {
      context.commit('GET_ACCOUNTADDRESS', payload);
    },
    updateAccountAddress(context, payload) {
      return axios.put(api + '/address', payload).then((res) => {
        context.commit('GET_ACCOUNTADDRESS', res.data);
      });
    },
    //                               MEMBERS
    getAccountMembers(context) {
      return axios.get(api + '/members').then((res) => {
        context.commit('GET_ACCOUNTMEMBERS', res.data);
      });
    },
    setAccountMembers: (context, payload) => {
      context.commit('GET_ACCOUNTMEMBERS', payload);
    },
    sentInviteMembers(context, payload) {
      return axios.post(api + '/members/invite', payload).then(() => {
        context.dispatch('getAccountMembers');
      });
    },
    updateAccountMembers(context, { id, data }) {
      return axios.put(api + '/members/' + id, data).then((res) => {
        context.dispatch('getAccountMembers');
      });
    },
    invitationAccountMembers(context, payload) {
      return axios.post(api + '/members/' + payload + '/resend-invitation').then((res) => {
        context.dispatch('getAccountMembers');
      });
    },
    deleteAccountMembers(context, payload) {
      return axios.delete(api + '/members/' + payload).then((res) => {
        context.dispatch('getAccountMembers');
      });
    },
    //                               BILLING
    getAccountBilling(context) {
      return axios.get(api + '/billing').then((res) => {
        context.commit('GET_ACCOUNTBILLING', res.data);
      });
    },
    setAccountBilling: (context, payload) => {
      context.commit('GET_ACCOUNTBILLING', payload);
    },
  },
  mutations: {
    GET_ACCOUNT: (state, payload) => {
      state.account = payload;
      return state.account;
    },
    GET_ACCOUNTADDRESS: (state, payload) => {
      state.accountAddress = payload;
      return state.accountAddress;
    },
    GET_ACCOUNTMEMBERS: (state, payload) => {
      state.members = payload;
      return state.members;
    },
    GET_ACCOUNTBILLING: (state, payload) => {
      state.billing = payload;
      return state.billing;
    },
    GET_ACCOUNTSLIST: (state, payload) => {
      state.accountsList = payload;
      return state.accountsList;
    },
  },
  getters: {
    account(state) {
      return state.account;
    },
    accountAddress(state) {
      return state.accountAddress;
    },
    members(state) {
      return state.members;
    },
    billing(state) {
      return state.billing;
    },
    accountsList(state) {
      return state.accountsList;
    },
  },
};
