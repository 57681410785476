import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/tickets/';
const url = window.location.host;
let socket = null;
let timerID = null;

export default {
  state: {
    supportList: {},
    support: { topic: {}, staff: {}, status: {} },
    supportMsg: [],
  },
  actions: {
    getSupportList(context, { pageSize, search, page, status }) {
      if (!!search || page > 1 || pageSize > 10 || status != 'all') {
        if (
          `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${search ? `src=${search}&` : ''}page=${
            +page || 1
          }&page_size=${pageSize}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${search ? `src=${search}&` : ''}page=${
              +page || 1
            }&page_size=${pageSize}`
          );
        }
        return axios
          .get(
            `${api}${status != 'all' ? `?status=${status}&` : '?'}${search ? `src=${search}&` : ''}page=${
              page - 1 || 0
            }&page_size=${pageSize}`
          )
          .then((res) => {
            context.commit('GET_SUPPORTLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_SUPPORTLIST', res.data);
        });
      }
    },
    setSupportList: (context, payload) => {
      context.commit('GET_SUPPORTLIST', payload);
    },
    createTicket(context, payload) {
      return axios.post(api, payload);
    },
    getTicket(context) {
      return axios.get(api + router.app._route.params.supportId).then((res) => {
        context.commit('GET_SUPPORT', res.data);
      });
    },
    setTicket: (context, payload) => {
      context.commit('GET_SUPPORT', payload);
    },
    actionTicket(context, payload) {
      return axios.post(api + router.app._route.params.supportId + '/action', payload).then((res) => {
        context.commit('GET_SUPPORT', res.data);
        context.dispatch('getTicketMsg');
      });
    },
    getTicketMsg(context) {
      return axios.get(api + router.app._route.params.supportId + '/messages/').then((res) => {
        context.commit('GET_SUPPORTMSG', res.data);
      });
    },
    setTicketMsg: (context, payload) => {
      context.commit('GET_SUPPORTMSG', payload);
    },
    sendTicketMsg(context, payload) {
      return axios({
        method: 'post',
        url: api + router.app._route.params.supportId + '/messages/',
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        context.dispatch('getTicketMsg');
      });
    },
    webSocketTicket(context) {
      socket = new WebSocket(
        `wss://${url}/customer/ws/v1/tickets/${router.app._route.params.supportId}?access_token=${sessionStorage.getItem('jwt_token')}&account_id=${context.getters.account.id}`
      );

      socket.onopen = (e) => {};

      socket.onclose = (e) => {};

      socket.onmessage = (e) => {
        if (JSON.parse(e.data).event == 'ticket_updated') {
          context.dispatch('getTicket');
        } else if (JSON.parse(e.data).event == 'ticket_message_updated') {
          context.dispatch('getTicketMsg');
        }
      };

      socket.onerror = (error) => {
        if (router.app._route.name == 'Ticket') {
          context.dispatch('checkTicket');
        }
      };
    },
    checkTicket(context) {
      if (!socket || socket.readyState == 3) {
        context.dispatch('webSocketTicket');
      }
    },
    timerTicket(context) {
      timerID = setInterval(() => {
        context.dispatch('checkTicket');
      }, 5000);
    },
    closeWebSocketTicket() {
      clearInterval(timerID);
      if (socket) {
        socket.close(1000);
      }
      socket = null;
    },
  },
  mutations: {
    GET_SUPPORTLIST: (state, payload) => {
      state.supportList = payload;
      return state.supportList;
    },
    GET_SUPPORT: (state, payload) => {
      state.support = payload;
      return state.support;
    },
    GET_SUPPORTMSG: (state, payload) => {
      state.supportMsg = payload;
      return state.supportMsg;
    },
  },
  getters: {
    supportList(state) {
      return state.supportList;
    },
    support(state) {
      return state.support;
    },
    supportMsg(state) {
      return state.supportMsg;
    },
  },
};
