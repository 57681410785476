<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout';
import MainLayout from '@/layouts/MainLayout';

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
};
</script>

<style>
#app * {
  font-family: 'Manrope', sans-serif !important;
}
* {
  color: #3e3e47;
  letter-spacing: 0;
}
.v-input {
  color: #3e3e47 !important;
}
* .error--text .v-messages__message {
  color: #ff4f4f;
}
span {
  color: inherit;
}
body,
#app {
  background: #f8f8f8;
}
a {
  text-decoration: none;
}
.f18 {
  font-size: 18px !important;
}
.f14 {
  font-size: 14px;
}
.mb-26 {
  margin-bottom: 26px;
}
.width100 {
  width: 100%;
}
.divider-vertical {
  background: #3e3e47;
  opacity: 20%;
  width: 1px;
  height: 20px;
  display: inline-block;
  margin-bottom: -5px;
}
.title {
  margin-bottom: -16px;
  width: 100%;
  height: 88px;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 60px 0 42px;
  background: radial-gradient(98.75% 7673.08% at 100% 10.25%, #fdae67 0%, #fda537 32.65%, #ff5a36 100%), #ff5a36;
}
.title-input {
  max-width: 620px;
  width: 100%;
}
.title-input .v-icon {
  z-index: 1;
}
.title-input .v-input__prepend-inner {
  margin-top: 12px !important;
}
.title-input fieldset {
  background: white !important;
}
.perPageSelect {
  margin-left: 10px;
  margin-right: 100px;
  padding-bottom: 5px;
  width: 58px;
}
.perPageSelect .v-input__append-inner {
  margin-top: 5px !important;
}
.label-table {
  max-width: 130px;
  width: 100%;
}
.v-picker__title__btn,
.v-picker__title__btn div,
table .v-select__selection {
  color: white !important;
}
.v-data-table__wrapper table tbody tr:nth-child(odd) {
  background: #f9f9f9;
}
table tbody tr td,
table thead tr th {
  font-size: 16px !important;
  font-weight: 500 !important;
}
table thead tr th {
  color: #8b8b91 !important;
}
.p-table .v-data-table__wrapper th:first-child,
.p-table .v-data-table__wrapper td:first-child {
  padding-left: 40px;
}
.p-table .v-data-table__wrapper th:last-child,
.p-table .v-data-table__wrapper td:last-child {
  padding-right: 40px;
}
.mdi-menu-down::before {
  content: '\F0140' !important;
}
.v-text-field--outlined fieldset {
  border-color: #d0d0d0;
}
.v-btn__content {
  font-size: 16px;
  text-transform: capitalize;
}
#app .bw {
  border: 1px solid #ffffff;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08) !important;
}
.v-data-table__checkbox .v-icon,
.v-input--checkbox .v-icon {
  font-size: 28px;
}
.v-input--checkbox .v-input__slot {
  align-items: start;
}
.v-input--selection-controls__input {
  height: 32px !important;
}
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}
.v-select .v-input__slot,
.v-input input {
  max-height: 48px;
}
textarea {
  padding: 4px 12px 4px 0 !important;
  min-height: 42px !important;
}
.v-text-field input {
  padding: 14px 0;
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 12px;
}
.v-select__selections {
  line-height: 24px;
}
#app .v-input__append-inner {
  margin-top: 13px;
}
#app .header-select .v-input__append-inner {
  margin-top: 0;
}
.v-list-item .v-list-item__title {
  line-height: 1.5;
}
.link {
  cursor: pointer;
}
.otp-input input {
  text-align: center;
}
.field42 .v-input__append-inner {
  margin-top: 11px !important;
}
.error-msg {
  position: absolute;
  margin-top: -25px;
}
.clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 959px) {
  .title-input {
    max-width: 450px;
  }
}
@media screen and (max-width: 599px) {
  .title {
    padding: 16px 16px 0 16px;
  }
  .title-input {
    width: initial;
  }
  .perPageSelect {
    margin-right: 10px;
  }
  table tbody tr td:last-child {
    width: 50%;
  }
  table tbody tr td,
  table thead tr th {
    font-size: 14px !important;
  }
}
</style>
