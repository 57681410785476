<template>
  <div>
    <v-navigation-drawer fixed overlay-opacity temporary v-model="drawer" width="334" class="mobile-menu">
      <v-list class="pa-0 py-4">
        <v-list-item link to="/profile">
          <v-list-item-avatar>
            <v-img v-if="data.photo != null" :src="data.photo"></v-img>
            <div v-else>
              <v-icon color="#3e3e47">mdi-robber mdi-48px</v-icon>
            </div>
          </v-list-item-avatar>
          <div class="d-flex flex-column">
            <v-list-item-title class="font-weight-bold">{{ data.first_name }} {{ data.last_name }}</v-list-item-title>
            <v-list-item-title class="align-self-start font-weight-medium text-capitalize opasity--text">
              {{ data.role }}
            </v-list-item-title>
          </div>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <div class="pa-5 user-select accent">
        <v-select
          v-model="account"
          :items="userList"
          item-text="name"
          item-value="id"
          :item-disabled="(item) => item.status === 'blocked'"
          menu-props="auto"
          return-object
          hide-details
          dense
          class="f18"
          @change="makeCurren"
        >
          <template v-slot:item="{ item }" disabled>
            <div class="d-flex width100">
              <v-icon v-if="account.id == item.id" class="mr-2" color="#ff9f36">mdi-crown</v-icon>
              <div :class="item.status == 'blocked' ? 'opasity--text' : ''">{{ item.name }}</div>
              <v-spacer></v-spacer>
              <div v-if="item.status == 'blocked'"><v-icon color="error">mdi-lock</v-icon></div>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <div class="d-flex width100">
              <div v-if="item.status == 'blocked'" class="mr-2"><v-icon color="error">mdi-lock</v-icon></div>
              <v-icon v-else class="mr-2" color="#ff9f36">mdi-crown</v-icon>
              <div>{{ item.name }}</div>
            </div>
          </template>
        </v-select>
      </div>
      <v-divider></v-divider>
      <div class="f18 font-weight-medium pa-5 accent">Balance: {{ user.balance }}</div>
      <v-divider></v-divider>
      <MenuList />
      <v-divider></v-divider>
      <v-btn width="190" height="64" text color="primary"><v-icon class="mr-8">mdi-download mdi-rotate-270</v-icon> Sign out</v-btn>
    </v-navigation-drawer>
    <v-app-bar color="accent" height="64" class="header" fixed>
      <v-app-bar-nav-icon @click="drawer = !drawer" class="d-lg-none d-block">
        <v-icon v-if="!drawer" color="#3e3e47">mdi-menu</v-icon>
        <v-icon v-else color="#3e3e47">mdi-close</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer class="d-lg-none d-block"></v-spacer>
      <div>
        <img height="42" class="mr-lg-0 mr-n16" src="@/assets/img/logo.svg" alt="logo" />
      </div>
      <div class="d-lg-flex d-none align-center">
        <div class="user-select mx-16">
          <v-select
            v-model="account"
            :items="userList"
            item-text="name"
            item-value="id"
            :item-disabled="(item) => item.status === 'blocked'"
            menu-props="auto"
            return-object
            hide-details
            dense
            class="f18"
            @change="makeCurren"
          >
            <template v-slot:item="{ item }" disabled>
              <div class="d-flex width100">
                <v-icon v-if="account.id == item.id" class="mr-2" color="#ff9f36">mdi-crown</v-icon>
                <div :class="item.status == 'blocked' ? 'opasity--text' : ''">{{ item.name }}</div>
                <v-spacer></v-spacer>
                <div v-if="item.status == 'blocked'"><v-icon color="error">mdi-lock</v-icon></div>
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <div class="d-flex width100">
                <div v-if="item.status == 'blocked'" class="mr-2"><v-icon color="error">mdi-lock</v-icon></div>
                <v-icon v-else class="mr-2" color="#ff9f36">mdi-crown</v-icon>
                <div>{{ item.name }}</div>
              </div>
            </template>
          </v-select>
        </div>
        <div class="f18">
          {{ user.balance }}
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y max-width="400px" nudge-bottom="8" nudge-left="300">
        <template v-slot:activator="{ on, attrs }">
          <v-badge bottom color="error" dot offset-x="20" offset-y="20" :value="count">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon color="#3e3e47">mdi-bell</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <v-card tile flat :width="'100%'" class="pa-5">
          <div class="d-flex justify-space-between flex-nowrap">
            <div class="text-h6">Notifications</div>
            <v-btn plain to="/notifications" text color="input">See All</v-btn>
          </div>
          <div v-if="notification.length" class="overflow-y-auto notifi">
            <div class="pt-1" v-for="item in notification" :key="item.id" @click="readItem(item.id)">
              <div class="d-flex justify-space-between">
                <div>
                  <div>{{ item.message }}</div>
                  <div class="opasity--text">{{ new Date(item.datetime).toLocaleString() }}</div>
                </div>
                <div v-if="item.is_read" class="mt-1 ml-1 input status rounded-circle"></div>
              </div>
              <v-divider class="my-1"></v-divider>
            </div>
          </div>
          <div v-else class="my-16 mx-sm-10 mx-4">
            <v-img height="80" contain src="@/assets/img/notFound.svg"></v-img>
            <div class="text-center font-weight-medium mt-3">You don't have any notifications</div>
          </div>
          <v-divider></v-divider>
          <div v-if="notification.length" class="text-right">
            <v-btn @click="readAll" text color="input">Read All</v-btn>
          </div>
        </v-card>
      </v-menu>
      <div class="text-center d-lg-block d-none ml-5">
        <v-menu offset-y nudge-bottom="4">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-list color="accent" class="pa-0">
                <v-list-item>
                  <div class="d-flex flex-column">
                    <v-list-item-title class="user-role clip font-weight-bold">
                      {{ data.first_name ? data.first_name.substr(0, 1) : '' }}. {{ data.last_name }}
                    </v-list-item-title>
                    <v-list-item-title class="align-self-end font-weight-medium text-capitalize opasity--text">
                      {{ data.role }}
                    </v-list-item-title>
                  </div>
                  <v-list-item-avatar>
                    <v-img v-if="data.photo != null" :src="data.photo"></v-img>
                    <div v-else>
                      <v-icon color="#3e3e47">mdi-robber mdi-48px</v-icon>
                    </div>
                  </v-list-item-avatar>
                </v-list-item>
              </v-list>
            </div>
          </template>
          <v-list>
            <v-list-item link to="/profile">
              <v-list-item-icon>
                <v-icon color="#3e3e47">mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="logout">
              <v-list-item-icon>
                <v-icon color="#3e3e47">mdi-download mdi-rotate-270</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sing out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  components: {
    MenuList,
  },
  data() {
    return {
      drawer: false,
      account: {},
      error: [],
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
    },
    async readItem(item) {
      if (!item.is_read) {
        await this.$store.dispatch('notificationsRead', item.id).then(() => {
          this.$store.dispatch('getNotificationsAll');
        });
      }
    },
    async readAll() {
      await this.$store.dispatch('notificationsReadAll');
    },
    async makeCurren() {
      await this.$store.dispatch('makeCurrentAcc', this.account.id);
    },
  },
  watch: {
    userList() {
      this.error = [];
      this.$store.dispatch('getAccount');
      this.$store.dispatch('getNotificationsAll');
      this.$store.dispatch('timer');
    },
    user() {
      this.account = this.user;
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
    userList() {
      return this.$store.getters.accountsList;
    },
    user() {
      return this.$store.getters.account;
    },
    notification() {
      return this.$store.getters.notificationsAll;
    },
    count() {
      return this.$store.getters.notificationsCount;
    },
  },
  destroyed() {
    this.$store.dispatch('closeWebSocket');
    this.$store.dispatch('setNotificationsAll', []);
    this.$store.dispatch('setAccount', {});
  },
};
</script>

<style>
/* .header .v-icon {
  color: #3e3e47 !important;
} */
.header .user-select {
  width: 300px;
}
.notifi {
  max-height: 300px;
  height: 100%;
}
.notifi .status {
  min-width: 12px;
  width: 12px;
  height: 12px;
}
.user-select .v-input__append-inner {
  margin-top: 4px !important;
}
.user-role {
  max-width: 160px;
  width: 100%;
}
.mobile-menu {
  top: 64px !important;
  height: calc(100% - 64px) !important;
}
.mobile-menu .v-list-item--link:before {
  background-color: initial;
}
.mobile-menu button {
  font-size: 18px !important;
  text-align: start !important;
}
</style>
