var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticClass:"mobile-menu",attrs:{"fixed":"","overlay-opacity":"","temporary":"","width":"334"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"pa-0 py-4"},[_c('v-list-item',{attrs:{"link":"","to":"/profile"}},[_c('v-list-item-avatar',[(_vm.data.photo != null)?_c('v-img',{attrs:{"src":_vm.data.photo}}):_c('div',[_c('v-icon',{attrs:{"color":"#3e3e47"}},[_vm._v("mdi-robber mdi-48px")])],1)],1),_c('div',{staticClass:"d-flex flex-column"},[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.data.first_name)+" "+_vm._s(_vm.data.last_name))]),_c('v-list-item-title',{staticClass:"align-self-start font-weight-medium text-capitalize opasity--text"},[_vm._v(" "+_vm._s(_vm.data.role)+" ")])],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"pa-5 user-select accent"},[_c('v-select',{staticClass:"f18",attrs:{"items":_vm.userList,"item-text":"name","item-value":"id","item-disabled":function (item) { return item.status === 'blocked'; },"menu-props":"auto","return-object":"","hide-details":"","dense":""},on:{"change":_vm.makeCurren},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex width100"},[(_vm.account.id == item.id)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ff9f36"}},[_vm._v("mdi-crown")]):_vm._e(),_c('div',{class:item.status == 'blocked' ? 'opasity--text' : ''},[_vm._v(_vm._s(item.name))]),_c('v-spacer'),(item.status == 'blocked')?_c('div',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-lock")])],1):_vm._e()],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex width100"},[(item.status == 'blocked')?_c('div',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-lock")])],1):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ff9f36"}},[_vm._v("mdi-crown")]),_c('div',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}})],1),_c('v-divider'),_c('div',{staticClass:"f18 font-weight-medium pa-5 accent"},[_vm._v("Balance: "+_vm._s(_vm.user.balance))]),_c('v-divider'),_c('MenuList'),_c('v-divider'),_c('v-btn',{attrs:{"width":"190","height":"64","text":"","color":"primary"}},[_c('v-icon',{staticClass:"mr-8"},[_vm._v("mdi-download mdi-rotate-270")]),_vm._v(" Sign out")],1)],1),_c('v-app-bar',{staticClass:"header",attrs:{"color":"accent","height":"64","fixed":""}},[_c('v-app-bar-nav-icon',{staticClass:"d-lg-none d-block",on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[(!_vm.drawer)?_c('v-icon',{attrs:{"color":"#3e3e47"}},[_vm._v("mdi-menu")]):_c('v-icon',{attrs:{"color":"#3e3e47"}},[_vm._v("mdi-close")])],1),_c('v-spacer',{staticClass:"d-lg-none d-block"}),_c('div',[_c('img',{staticClass:"mr-lg-0 mr-n16",attrs:{"height":"42","src":require("@/assets/img/logo.svg"),"alt":"logo"}})]),_c('div',{staticClass:"d-lg-flex d-none align-center"},[_c('div',{staticClass:"user-select mx-16"},[_c('v-select',{staticClass:"f18",attrs:{"items":_vm.userList,"item-text":"name","item-value":"id","item-disabled":function (item) { return item.status === 'blocked'; },"menu-props":"auto","return-object":"","hide-details":"","dense":""},on:{"change":_vm.makeCurren},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex width100"},[(_vm.account.id == item.id)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ff9f36"}},[_vm._v("mdi-crown")]):_vm._e(),_c('div',{class:item.status == 'blocked' ? 'opasity--text' : ''},[_vm._v(_vm._s(item.name))]),_c('v-spacer'),(item.status == 'blocked')?_c('div',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-lock")])],1):_vm._e()],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex width100"},[(item.status == 'blocked')?_c('div',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-lock")])],1):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ff9f36"}},[_vm._v("mdi-crown")]),_c('div',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}})],1),_c('div',{staticClass:"f18"},[_vm._v(" "+_vm._s(_vm.user.balance)+" ")])]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","max-width":"400px","nudge-bottom":"8","nudge-left":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"bottom":"","color":"error","dot":"","offset-x":"20","offset-y":"20","value":_vm.count}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#3e3e47"}},[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-card',{staticClass:"pa-5",attrs:{"tile":"","flat":"","width":'100%'}},[_c('div',{staticClass:"d-flex justify-space-between flex-nowrap"},[_c('div',{staticClass:"text-h6"},[_vm._v("Notifications")]),_c('v-btn',{attrs:{"plain":"","to":"/notifications","text":"","color":"input"}},[_vm._v("See All")])],1),(_vm.notification.length)?_c('div',{staticClass:"overflow-y-auto notifi"},_vm._l((_vm.notification),function(item){return _c('div',{key:item.id,staticClass:"pt-1",on:{"click":function($event){return _vm.readItem(item.id)}}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('div',[_vm._v(_vm._s(item.message))]),_c('div',{staticClass:"opasity--text"},[_vm._v(_vm._s(new Date(item.datetime).toLocaleString()))])]),(item.is_read)?_c('div',{staticClass:"mt-1 ml-1 input status rounded-circle"}):_vm._e()]),_c('v-divider',{staticClass:"my-1"})],1)}),0):_c('div',{staticClass:"my-16 mx-sm-10 mx-4"},[_c('v-img',{attrs:{"height":"80","contain":"","src":require("@/assets/img/notFound.svg")}}),_c('div',{staticClass:"text-center font-weight-medium mt-3"},[_vm._v("You don't have any notifications")])],1),_c('v-divider'),(_vm.notification.length)?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"input"},on:{"click":_vm.readAll}},[_vm._v("Read All")])],1):_vm._e()],1)],1),_c('div',{staticClass:"text-center d-lg-block d-none ml-5"},[_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list',{staticClass:"pa-0",attrs:{"color":"accent"}},[_c('v-list-item',[_c('div',{staticClass:"d-flex flex-column"},[_c('v-list-item-title',{staticClass:"user-role clip font-weight-bold"},[_vm._v(" "+_vm._s(_vm.data.first_name ? _vm.data.first_name.substr(0, 1) : '')+". "+_vm._s(_vm.data.last_name)+" ")]),_c('v-list-item-title',{staticClass:"align-self-end font-weight-medium text-capitalize opasity--text"},[_vm._v(" "+_vm._s(_vm.data.role)+" ")])],1),_c('v-list-item-avatar',[(_vm.data.photo != null)?_c('v-img',{attrs:{"src":_vm.data.photo}}):_c('div',[_c('v-icon',{attrs:{"color":"#3e3e47"}},[_vm._v("mdi-robber mdi-48px")])],1)],1)],1)],1)],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":"/profile"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"#3e3e47"}},[_vm._v("mdi-account")])],1),_c('v-list-item-title',[_vm._v("Profile")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"#3e3e47"}},[_vm._v("mdi-download mdi-rotate-270")])],1),_c('v-list-item-title',[_vm._v("Sing out")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }