import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/dashboard',
      name: 'Dashboard',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Dashboard.vue'),
    },
    {
      path: '/campaigns',
      name: 'Campaigns',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/CampaignsList.vue'),
    },
    {
      path: '/campaigns/:campaignsId',
      name: 'Campaigns item',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Campaigns.vue'),
    },
    {
      path: '/campaigns-new',
      name: 'Campaigns new',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/CampaignsNew.vue'),
    },
    {
      path: '/messages',
      name: 'Messages history',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/MessagesList.vue'),
    },
    {
      path: '/messages/:msgId',
      name: 'Messages',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Messages.vue'),
    },
    {
      path: '/statistics',
      name: 'Statistics',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Statistics.vue'),
    },
    {
      path: '/groups',
      name: 'Groups',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/GroupsList.vue'),
    },
    {
      path: '/groups/:groupId',
      name: 'Group',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Group.vue'),
    },
    {
      path: '/groups/:groupId/contact',
      name: 'Group Contact',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/GroupContact.vue'),
    },
    {
      path: '/groups/:groupId/progress-import',
      name: 'Group import prodress',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/GroupProgress.vue'),
    },
    {
      path: '/prices',
      name: 'Prices',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Prices.vue'),
    },
    {
      path: '/invoices',
      name: 'Invoices',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Invoices.vue'),
    },
    {
      path: '/account',
      name: 'Account',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Account.vue'),
    },
    {
      path: '/rest',
      name: 'REST API',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/RestAPI.vue'),
    },
    {
      path: '/smpp',
      name: 'SMPP API',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/SMPPAPI.vue'),
    },
    {
      path: '/support',
      name: 'Support tickets',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/SupportList.vue'),
    },
    {
      path: '/support/:supportId',
      name: 'Ticket',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Support.vue'),
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Profile.vue'),
    },
    {
      path: '/notifications',
      name: 'Notifications',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Notifications.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/sign-up',
      name: 'Sign-up',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Registration.vue'),
    },
    {
      path: '/sign-up-invitation',
      name: 'Sign-up invitation',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/RegistInvitation.vue'),
    },
    {
      path: '/password-recovery',
      name: 'Password',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Password.vue'),
    },
    {
      path: '/password-reset',
      name: 'PasswordNew',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/PasswordNew.vue'),
    },
    {
      path: '/email-confirmation',
      name: 'Confirmation',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Confirmation.vue'),
    },
    {
      path: '/account-invitation',
      name: 'Confirmation account',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Invitation.vue'),
    },
    {
      path: '/account-invitation-error',
      name: 'Confirmation account error',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/InvitationError.vue'),
    },
    {
      path: '/account-email-confirmation',
      name: 'Account confirmation',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/ConfirmationAccount.vue'),
    },
    {
      path: '/already-confirmed',
      name: 'Already',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Already.vue'),
    },
    {
      path: '/failed-confirmed',
      name: 'Failed',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Failed.vue'),
    },
    {
      path: '/expired',
      name: 'Expired',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Expired.vue'),
    },
    {
      path: '/expired-invitation',
      name: 'Expired invitation',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/InvitationExpired.vue'),
    },
    {
      path: '/re-sent',
      name: 'ReSent',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/ReSent.vue'),
    },
    {
      path: '/account-blocked',
      name: 'Blocked',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Blocked.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'Privacy policy',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Privacy.vue'),
    },
    {
      path: '/terms-of-service',
      name: 'Terms of Service',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Terms.vue'),
    },
    {
      path: '*',
      redirect: { name: 'Dashboard' },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = sessionStorage.getItem('jwt_token');
  const requireAuth = to.matched.some((record) => record.meta.auth);

  if (requireAuth && !currentUser) {
    next({ name: 'Login', query: { from: to.fullPath } });
  } else {
    next();
  }
});

export default router;
