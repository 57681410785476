import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: true,
      light: {
        primary: '#FF5A36',
        secondary: '#3E3E47',
        error: '#FF4F4F',
        warning: '#ffc936',
        accent: '#F8F8F8',
        success: '#65C006',
        input: '#577BF9',
        opasity: '#8B8B91',
        gray: '#C8C8C8',
      },
    },
  },
});
