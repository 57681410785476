import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/prices/';

export default {
  state: {
    prices: {},
  },
  actions: {
    getPrices(context, { pageSize, search, page }) {
      if (!!search || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}${search ? `?src=${search}&` : '?'}page=${+page || 1}&page_size=${pageSize}` !=
          router.app._route.fullPath
        ) {
          router.push(`${router.app._route.path}${search ? `?src=${search}&` : '?'}page=${+page || 1}&page_size=${pageSize}`);
        }
        return axios.get(`${api}${search ? `?src=${search}&` : '?'}page=${page - 1 || 0}&page_size=${pageSize}`).then((res) => {
          context.commit('GET_PRICES', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_PRICES', res.data);
        });
      }
    },
    setPrices: (context, payload) => {
      context.commit('GET_PRICES', payload);
    },
  },
  mutations: {
    GET_PRICES: (state, payload) => {
      state.prices = payload;
      return state.prices;
    },
  },
  getters: {
    prices(state) {
      return state.prices;
    },
  },
};
