import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/groups/';

export default {
  state: {
    groupsList: {},
    groupsAll: [],
    group: {},
    groupsVars: [],
    contactsList: {},
    importPreview: {},
    importProgress: {},
    id: '',
  },
  actions: {
    getGroupsList(context, { pageSize, search, page }) {
      if (!!search || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${+page || 1}&page_size=${pageSize}` !=
          router.app._route.fullPath
        ) {
          router.push(`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${+page || 1}&page_size=${pageSize}`);
        }
        return axios.get(`${api}${!!search ? `?src=${search}&` : '?'}page=${page - 1 || 0}&page_size=${pageSize}`).then((res) => {
          context.commit('GET_GROUPSLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${router.app._route.query.page - 1 || 0}`).then((res) => {
          context.commit('GET_GROUPSLIST', res.data);
        });
      }
    },
    setGroupsList: (context, payload) => {
      context.commit('GET_GROUPSLIST', payload);
    },
    createGroups(context, payload) {
      return axios.post(api, payload);
    },
    updateGroups(context, { id, data }) {
      return axios.put(api + id, data);
    },
    deleteGroups(context, payload) {
      return axios.delete(api + payload);
    },
    getGroupsAll(context) {
      return axios.get(api + 'all').then((res) => {
        context.commit('GET_GROUPSALL', res.data);
      });
    },
    setGroupsAll: (context, payload) => {
      context.commit('GET_GROUPSALL', payload);
    },
    getGroup(context) {
      return axios.get(api + router.app._route.params.groupId).then((res) => {
        context.commit('GET_GROUP', res.data);
      });
    },
    setGroup: (context, payload) => {
      context.commit('GET_GROUP', payload);
    },
    //                VARS
    getGroupsVars(context, payload) {
      return axios.get(api + payload + '/vars/').then((res) => {
        context.commit('GET_GROUPSVARS', res.data);
      });
    },
    setGroupsVars: (context, payload) => {
      context.commit('GET_GROUPSVARS', payload);
    },
    newGroupsVars(context, payload) {
      return axios.post(api + router.app._route.params.groupId + '/vars/', payload);
    },
    getGroupsItemVars(context, payload) {
      return axios.get(api + router.app._route.params.groupId + '/vars/' + payload);
    },
    updeteGroupsItemVars(context, { id, data }) {
      return axios.put(api + router.app._route.params.groupId + '/vars/' + id, data);
    },
    deleteGroupsItemVars(context, payload) {
      return axios.delete(api + router.app._route.params.groupId + '/vars/' + payload);
    },
    //                  CONTACTS
    getGroupsContantsList(context, { pageSize, search, page }) {
      if (!!search || page > 1 || pageSize > 10) {
        if (
          `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}` !=
          router.app._route.fullPath
        ) {
          router.push(`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}&page_size=${pageSize}`);
        }
        return axios
          .get(
            `${api}${router.app._route.params.groupId}/contacts${!!search ? `?src=${search}&` : '?'}page=${
              page - 1 || 0
            }&page_size=${pageSize}`
          )
          .then((res) => {
            context.commit('GET_GROUPSCONTACTSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}${router.app._route.params.groupId}/contacts/?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_GROUPSCONTACTSLIST', res.data);
        });
      }
    },
    setGroupsContantsList(context, payload) {
      context.commit('GET_GROUPSCONTACTSLIST', payload);
    },
    newGroupsContants(context, payload) {
      return axios.post(api + router.app._route.params.groupId + '/contacts/', payload);
    },
    updateGroupsContantsItem(context, { id, data }) {
      return axios.put(api + router.app._route.params.groupId + '/contacts/' + id, data);
    },
    deleteGroupsContantsItem(context, payload) {
      return axios.delete(api + router.app._route.params.groupId + '/contacts/' + payload);
    },
    importPreContants(context, payload) {
      return axios({
        method: 'post',
        url: api + router.app._route.params.groupId + '/contacts/import-preview',
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_IMPORTPREVIEW', res.data);
      });
    },
    setImportPreContants(context, payload) {
      context.commit('GET_IMPORTPREVIEW', payload);
    },
    importContants(context, payload) {
      return axios({
        method: 'post',
        url: api + router.app._route.params.groupId + '/contacts/import',
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    importProgContants(context) {
      let timerID = null;
      return axios.get(api + router.app._route.params.groupId + '/contacts/import-progress').then((res) => {
        context.commit('GET_IMPORTPROGRESS', res.data);
        timerID = setTimeout(() => {
          clearTimeout(timerID);
          context.dispatch('importProgContants');
        }, 1000);
        if (res.data.status == 'finished') {
          clearTimeout(timerID);
        }
      });
    },
    importProgContantsBtn(context) {
      return axios.get(api + router.app._route.params.groupId + '/contacts/import-progress').then((res) => {
        context.commit('GET_IMPORTPROGRESS', res.data);
      });
    },
    setImportProgContants(context, payload) {
      context.commit('GET_IMPORTPROGRESS', payload);
    },
    setId: (context, payload) => {
      context.commit('GET_ID', payload);
    },
  },
  mutations: {
    GET_GROUPSLIST: (state, payload) => {
      state.groupsList = payload;
      return state.groupsList;
    },
    GET_GROUPSALL: (state, payload) => {
      state.groupsAll = payload;
      return state.groupsAll;
    },
    GET_GROUP: (state, payload) => {
      state.group = payload;
      return state.group;
    },
    GET_GROUPSVARS: (state, payload) => {
      state.groupsVars = payload;
      return state.groupsVars;
    },
    GET_GROUPSCONTACTSLIST: (state, payload) => {
      state.contactsList = payload;
      return state.contactsList;
    },
    GET_IMPORTPREVIEW: (state, payload) => {
      state.importPreview = payload;
      return state.importPreview;
    },
    GET_IMPORTPROGRESS: (state, payload) => {
      state.importProgress = payload;
      return state.importProgress;
    },
    GET_ID: (state, payload) => {
      state.id = payload;
      return state.id;
    },
  },
  getters: {
    groupsList(state) {
      return state.groupsList;
    },
    groupsAll(state) {
      return state.groupsAll;
    },
    group(state) {
      return state.group;
    },
    groupsVars(state) {
      return state.groupsVars;
    },
    contactsList(state) {
      return state.contactsList;
    },
    importPreview(state) {
      return state.importPreview;
    },
    importProgress(state) {
      return state.importProgress;
    },
    id(state) {
      return state.id;
    },
  },
};
