import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/campaigns/';

export default {
  state: {
    campaignsList: {},
    campaignNew: {},
    constraints: {},
    campaignItem: { status: {}, send_option: {}, group: {}, targets_type: {} },
    campaignItemStat: {},
    campaignMsg: {},
    campaignLinkStat: [],
  },
  actions: {
    getCampaignsList(context, { pageSize, search, page, status }) {
      if (!!search || page > 1 || pageSize > 10 || status != 'all') {
        if (
          `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${!!search ? `src=${search}&` : ''}page=${
            +page || 1
          }&page_size=${pageSize}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${!!search ? `src=${search}&` : ''}page=${
              +page || 1
            }&page_size=${pageSize}`
          );
        }
        return axios
          .get(
            `${api}${status != 'all' ? `?status=${status}&` : '?'}${!!search ? `src=${search}&` : ''}page=${
              page - 1 || 0
            }&page_size=${pageSize}`
          )
          .then((res) => {
            context.commit('GET_CAMPAIGNS', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${router.app._route.query.page - 1 || 0}`).then((res) => {
          context.commit('GET_CAMPAIGNS', res.data);
        });
      }
    },
    setCampaignsList: (context, payload) => {
      context.commit('GET_CAMPAIGNS', payload);
    },
    sentCampaign(context, { action, data }) {
      return axios.post(`${api}?dry_run=${action}`, data).then((res) => {
        context.commit('GET_CAMPAIGNNEW', res.data);
        if (!action) {
          router.push(`/campaigns/${res.data.id}`);
        }
      });
    },
    setCampaignNew(context, payload) {
      context.commit('GET_CAMPAIGNNEW', payload);
    },
    getConstraints(context) {
      return axios.get(api + 'constraints').then((res) => {
        context.commit('GET_CONSTRAINTS', res.data);
      });
    },
    setConstraints: (context, payload) => {
      context.commit('GET_CONSTRAINTS', payload);
    },
    getCampaignItem(context) {
      return axios.get(api + router.app._route.params.campaignsId).then((res) => {
        context.commit('GET_CAMPAIGNITEM', res.data);
      });
    },
    setCampaignItem: (context, payload) => {
      context.commit('GET_CAMPAIGNITEM', payload);
    },
    getCampaignMsg(context, { pageSize, search, page, status }) {
      if (pageSize > 10 || status != 'all' || !!search || page > 1) {
        if (
          `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${!!search ? `src=${search}&` : ''}page=${
            page || 1
          }&page_size=${pageSize}` != router.app._route.fullPath
        ) {
          window.history.pushState(
            {},
            '',
            `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${!!search ? `src=${search}&` : ''}page=${
              page || 1
            }&page_size=${pageSize}`
          );
        }
        return axios
          .get(
            `${api + router.app._route.params.campaignsId + '/messages/'}${status != 'all' ? `?status=${status}&` : '?'}${
              !!search ? `src=${search}&` : ''
            }page=${page - 1 || 0}&page_size=${pageSize}`
          )
          .then((res) => {
            context.commit('GET_CAMPAIGNMSG', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          window.history.pushState({}, '', `${router.app._route.path}`);
        }
        return axios.get(`${api + router.app._route.params.campaignsId + '/messages/'}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_CAMPAIGNMSG', res.data);
        });
      }
    },
    setCampaignMsg: (context, payload) => {
      context.commit('GET_CAMPAIGNMSG', payload);
    },
    getCampaignItemStat(context) {
      return axios.get(`${api}${router.app._route.params.campaignsId}/statistics`).then((res) => {
        context.commit('GET_CAMPAIGNITEMSTAT', res.data);
      });
    },
    setCampaignItemStat: (context, payload) => {
      context.commit('GET_CAMPAIGNITEMSTAT', payload);
    },
    getCampaignLinkStat(context, payload) {
      return axios.get(`${api}${router.app._route.params.campaignsId}/link-statistics${payload ? payload : ''}`).then((res) => {
        context.commit('GET_CAMPAIGNLINKSTAT', res.data);
      });
    },
    setCampaignLinkStat: (context) => {
      context.commit('GET_CAMPAIGNLINKSTAT', []);
    },
  },
  mutations: {
    GET_CAMPAIGNS: (state, payload) => {
      state.campaignsList = payload;
      return state.campaignsList;
    },
    GET_CAMPAIGNNEW: (state, payload) => {
      state.campaignNew = payload;
      return state.campaignNew;
    },
    GET_CONSTRAINTS: (state, payload) => {
      state.constraints = payload;
      return state.constraints;
    },
    GET_CAMPAIGNITEM: (state, payload) => {
      state.campaignItem = payload;
      return state.campaignItem;
    },
    GET_CAMPAIGNITEMSTAT: (state, payload) => {
      state.campaignItemStat = payload;
      return state.campaignItemStat;
    },
    GET_CAMPAIGNMSG: (state, payload) => {
      state.campaignMsg = payload;
      return state.campaignMsg;
    },
    GET_CAMPAIGNLINKSTAT: (state, payload) => {
      state.campaignLinkStat = payload;
      return state.campaignLinkStat;
    },
  },
  getters: {
    campaignsList(state) {
      return state.campaignsList;
    },
    campaignNew(state) {
      return state.campaignNew;
    },
    constraints(state) {
      return state.constraints;
    },
    campaignItem(state) {
      return state.campaignItem;
    },
    campaignItemStat(state) {
      return state.campaignItemStat;
    },
    campaignMsg(state) {
      return state.campaignMsg;
    },
    campaignLinkStat(state) {
      return state.campaignLinkStat;
    },
  },
};
