import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/';

const url = window.location.host;
let socket = null;
let timerID = null;

export default {
  state: {
    countries: [],
    city: [],
    pagination: [],
    periods: [{}],
    roleList: [],
    msgStatus: [],
    campaignFilter: [],
    ticketStatus: [],
    ticketTopic: [],
  },
  actions: {
    getCoutries(context) {
      return axios.get(api + 'countries/').then((res) => {
        context.commit('GET_COUNTRIES', res.data);
      });
    },
    setCoutries: (context, payload) => {
      context.commit('GET_COUNTRIES', payload);
    },
    getCity(context, payload) {
      return axios.get(`${api}countries/${payload}/cities/`).then((res) => {
        context.commit('GET_CITY', res.data);
      });
    },
    setCity: (context, payload) => {
      context.commit('GET_CITY', payload);
    },
    getPagination(context) {
      return axios.get(api + 'pagination-options/').then((res) => {
        context.commit('GET_PAGINATION', res.data);
      });
    },
    getPeriods(context) {
      return axios.get(api + 'periods/').then((res) => {
        context.commit('GET_PERIODS', res.data);
      });
    },
    getRoles(context) {
      return axios.get(api + 'roles/').then((res) => {
        context.commit('GET_ROLELIST', res.data);
      });
    },
    getMsgStatus(context) {
      return axios.get(api + 'message-statuses/').then((res) => {
        context.commit('GET_MSGSTATUS', res.data);
      });
    },
    getCampaignFilter(context) {
      return axios.get(api + 'campaign-filters/').then((res) => {
        context.commit('GET_CAMPAIGNFILTER', res.data);
      });
    },
    getTicketStatus(context) {
      return axios.get(api + 'tickets-statuses/').then((res) => {
        context.commit('GET_TICKETSTATUS', res.data);
      });
    },
    getTicketTopic(context) {
      return axios.get(api + 'tickets-topics/').then((res) => {
        context.commit('GET_TICKETTOPIC', res.data);
      });
    },
    webSocket(context) {
      socket = new WebSocket(
        `wss://${url}/customer/ws/v1/?access_token=${sessionStorage.getItem('jwt_token')}&account_id=${context.getters.account.id}`
      );

      socket.onopen = (e) => {};

      socket.onclose = () => {
        if (router.app._route.name != 'Login') {
          context.dispatch('check');
        }
      };

      socket.onmessage = (e) => {
        if (JSON.parse(e.data).event == 'notification') {
          context.dispatch('getNotificationsAll');
        } else if (JSON.parse(e.data).event == 'unread_notification_count') {
          context.dispatch('getNotificationsCount');
        }
      };

      socket.onerror = () => {
        if (router.app._route.name != 'Login') {
          context.dispatch('check');
        }
      };
    },
    check(context) {
      if (!socket || socket.readyState == 3) {
        context.dispatch('webSocket');
      }
    },
    timer(context) {
      timerID = setInterval(() => {
        context.dispatch('check');
      }, 5000);
    },
    closeWebSocket() {
      clearInterval(timerID);
      if (socket) {
        socket.close(1000);
      }
      socket = null;
    },
  },
  mutations: {
    GET_COUNTRIES: (state, payload) => {
      state.countries = payload;
      return state.countries;
    },
    GET_CITY: (state, payload) => {
      state.city = payload;
      return state.city;
    },
    GET_PAGINATION: (state, payload) => {
      state.pagination = payload;
      return state.pagination;
    },
    GET_PERIODS: (state, payload) => {
      state.periods = payload;
      return state.periods;
    },
    GET_ROLELIST: (state, payload) => {
      state.roleList = payload;
      return state.roleList;
    },
    GET_MSGSTATUS: (state, payload) => {
      state.msgStatus = payload;
      return state.msgStatus;
    },
    GET_CAMPAIGNFILTER: (state, payload) => {
      state.campaignFilter = payload;
      return state.campaignFilter;
    },
    GET_TICKETSTATUS: (state, payload) => {
      state.ticketStatus = payload;
      return state.ticketStatus;
    },
    GET_TICKETTOPIC: (state, payload) => {
      state.ticketTopic = payload;
      return state.ticketTopic;
    },
  },
  getters: {
    countries(state) {
      return state.countries;
    },
    city(state) {
      return state.city;
    },
    pagination(state) {
      return state.pagination;
    },
    periods(state) {
      return state.periods;
    },
    roleList(state) {
      return state.roleList;
    },
    msgStatus(state) {
      return state.msgStatus;
    },
    campaignFilter(state) {
      return state.campaignFilter;
    },
    ticketStatus(state) {
      return state.ticketStatus;
    },
    ticketTopic(state) {
      return state.ticketTopic;
    },
  },
};
