<template>
  <div class="main-layout">
    <Header />
    <Menu class="d-none d-lg-block" />
    <notifications></notifications>
    <router-view class="wrap" />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Menu from '@/components/Menu';
import Header from '@/components/Header';
// import Footer from '@/components/Footer';

export default {
  name: 'main-loyout',
  components: {
    Menu,
    // Footer,
    Header,
  },
  mounted() {
    this.$store.dispatch('getAccountsList').catch((e) => {
      const error = e.response.data.error[0];
      if (error == 'account__blocked') {
        this.notifi('company');
      } else if (error == 'user__blocked') {
        this.notifi('account');
      }
    });
    setTimeout(() => {
      this.$store.dispatch('getProfile');
    }, 300);
  },
  methods: {
    notifi(btn) {
      if (btn == 'company') {
        this.$notify({
          message: 'Sorry, but the company account is blocked',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'account') {
        this.$notify({
          message: 'Sorry, but your account is blocked',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
  },
};
</script>

<style>
.main-layout .wrap {
  margin: 64px 0 40px 56px;
}
@media screen and (max-width: 1263px) {
  .main-layout .wrap {
    margin: 64px 0 40px 0;
  }
}
</style>
