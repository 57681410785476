import '@/plugins/axios';

const api = '/customer/api/v1/invitations';

export default {
  state: {
    invitation: {},
  },
  actions: {
    invitationAccept(context, payload) {
      return axios.post(`${api}/${payload}/accept`);
    },
    invitationReject(context, payload) {
      return axios.post(`${api}/${payload}/reject`);
    },
  },
  mutations: {
    GET_INVITATION: (state, payload) => {
      state.invitation = payload;
      return state.invitation;
    },
  },
  getters: {
    invitation(state) {
      return state.invitation;
    },
  },
};
