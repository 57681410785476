import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import auth from './modules/auth';
import invitation from './modules/invitation';
import profile from './modules/profile';
import groups from './modules/groups';
import campaigns from './modules/campaigns';
import messages from './modules/messages';
import prices from './modules/prices';
import externalApi from './modules/externalApi';
import account from './modules/account';
import support from './modules/support';
import notifications from './modules/notifications';
import invoices from './modules/invoices';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    auth,
    invitation,
    profile,
    groups,
    campaigns,
    messages,
    prices,
    externalApi,
    account,
    support,
    notifications,
    invoices,
  },
});
