'use strict';

import Vue from 'vue';
import axios from 'axios';
import store from './../store/index';
import router from '@/router/index';

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.headers.common['X-Auth-Token'] = `${sessionStorage.getItem('jwt_token')}`;
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ''
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('jwt_token');

    if (token) {
      config.headers['X-Auth-Token'] = `${token}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  async function(error) {
    // Do something with response error
    if (error.response.status == 401) {
      await store.dispatch('refresh');
      error.response.config.headers['X-Auth-Token'] = `${sessionStorage.getItem('jwt_token')}`;
      return axios(error.response.config);
    } else if (error.response.data.error[0] == 'account__blocked') {
      // this.$notify({
      //   message: 'Sorry, but the company account is blocked',
      //   type: 'error',
      //   horizontalAlign: 'center',
      //   verticalAlign: 'top',
      // });
      // setTimeout(() => {
      //   const loginpath = window.location.pathname;
      //   router.push({ name: 'Login', query: { from: loginpath } });
      // }, 100);
      router.push('/');
    } else if (error.response.data.error[0] == 'user__blocked') {
      // this.$notify({ message: 'Sorry, but your account is blocked', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });   
      sessionStorage.removeItem('jwt_token');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
