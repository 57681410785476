import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/invoices/';

export default {
  state: {
    invoicesList: {},
    invoicesFile: '',
  },
  actions: {
    getInvoicesList(context, { page, pageSize, search }) {
      if (!!search || pageSize > 10 || page > 1) {
        if (
          `${router.app._route.path}?page=${page}&page_size=${pageSize}${!!search ? `&src=${search}` : ''}` != router.app._route.fullPath
        ) {
          router.push(`${router.app._route.path}?page=${page}&page_size=${pageSize}${!!search ? `&src=${search}` : ''}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}&page_size=${pageSize}${!!search ? `&src=${search}` : ''}`).then((res) => {
          context.commit('GET_INVOICESLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_INVOICESLIST', res.data);
        });
      }
    },
    setInvoicesList: (context, payload) => {
      context.commit('GET_INVOICESLIST', payload);
    },
    getInvoicesFile(context, { id, type }) {
      return axios.get(`${api}${id}/download?format=${type}`).then((res) => {
        context.commit('GET_INVOICESFILE', res.data);
      });
    },
  },
  mutations: {
    GET_INVOICESLIST: (state, payload) => {
      state.invoicesList = payload;
      return state.invoicesList;
    },
    GET_INVOICESFILE: (state, payload) => {
      state.invoicesFile = payload;
      return state.invoicesFile;
    },
  },
  getters: {
    invoicesList(state) {
      return state.invoicesList;
    },
    invoicesFile(state) {
      return state.invoicesFile;
    },
  },
};
